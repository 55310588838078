import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const Feature = ({ color, item, index }) => {
  const imageLeft = (index + 1) % 2
  return (
    <Row className='mb-4 mb-md-0'>
      <Col
        xs={12} md={{span: 6, order: imageLeft === 0 ? 1 : 0}}
        className={`p-3 p-lg-5 d-flex flex-column justify-content-center ${color}`}
      >
        <div className={`d-flex flex-column justify-content-center p${imageLeft ? 's': 'e'}-lg-5`}>
          {item.title && (
            <>
              {item.h2 && (
                <h2 className="display-6 mb-3 fw-semibold">
                  {item.title}
                </h2>
              )}
              {!item.h2 && (
                <div className="display-6 mb-3 fw-semibold">
                  {item.title}
                </div>
              )}
            </>
          )}
          {item.text && (
            <p className="lead mb-0">
              {item.text}
            </p>
          )}
          {item.buttons && (
            <div className="mt-4 d-flex flex-column flex-xl-row align-items-stretch justify-content-start">
              {item.buttons}
            </div>
          )}
        </div>
      </Col>
      <Col
        xs={12} md={{span: 6, order: imageLeft === 0 ? 0 : 1}}
        className='p-3 py-lg-4 d-flex justify-content-center'
      >
        {item.image}
      </Col>
    </Row>
  )
}

const Features = ({
  title,
  text,
  items,
  background = 'bg-light',
  color = 'text-black',
  buttons,
  h2 = true
}) => {
  return (
    <Row className='my-5'>
      <Col xs={12}>
        <div className={`w-100 ${background} py-2 px-3 px-xl-1 br-3`}>
          {title && (
            <>
              {h2 && (
                <h2 className={`display-5 fw-semibold text-center mt-4 mb-5 ${color}`}>
                  {title}
                </h2>
              )}
              {!h2 && (
                <div className={`display-5 fw-semibold text-center mt-4 mb-5 ${color}`}>
                  {title}
                </div>
              )}
            </>
          )}
          {text && (
            <p className={`fs-4 mb-5 text-center ${color}`}>
              {text}
            </p>
          )}

          {items.map((item, index) => (
            <div key={index}>
              <Feature
                item={item}
                index={index}
                color={color}
              />
            </div>
          )) }

          { buttons && (
            <div className='my-5 d-flex justify-content-center gap-3'>
              {buttons}
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Features
