import Image from 'next/image'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const Quote = ({ text, name, image, subtitle }) => {
  const imageSize = 36

  return (
    <>
      <Row className='my-5 mt-0 d-flex justify-content-center'>
        <Col xs={12} lg={6}>
          <Row className="d-flex flex-row align-items-center justify-content-center">
            <Col
              xs={12} md={6}
              className='d-flex flex-row align-items-center gap-3'
            >
              <div className='fw-bold text-warning'>
              <span style={{
                lineHeight: 0,
                fontSize: 72,
                verticalAlign: 'bottom'
              }}>“</span>
              </div>
              <div>
                {text}
              </div>
              <div
                className='d-none d-md-block'
                style={{
                  width: 1,
                  height: 60,
                  background: '#ddd'
                }}
              />
            </Col>
            <Col
              xs={12}
              className='d-md-none d-flex justify-content-center my-3'
            >
              <div
                style={{
                  flex: '0 0 50%',
                  width: '50%',
                  height: 1,
                  background: '#ddd'
                }}
              />
            </Col>
            <Col
              xs={12} md={3}
              className='d-flex justify-content-center gap-2'
            >
              <div
                className='bg-success flex-shrink-0'
                style={{
                  borderRadius: '100%',
                  width: imageSize,
                  height: imageSize
                }}
              >
                <Image
                  src={image}
                  alt={`${name} | Iyvflip Review`}
                  loading="lazy"
                  width={ imageSize}
                  height={ imageSize}
                  style={{ borderRadius: '100%' }}
                />
              </div>
              <div className='d-flex flex-column justify-content-center'>
                <div className="fs-6">{name}</div>
                {subtitle && (<div>{subtitle}</div>)}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Quote
