import HeroImage from './HeroImage'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FeaturesList from './FeaturesList'
import PrimaryCtaButton from './PrimaryCtaButton'
import styles from '../../styles/components/AppIntroduction.module.css'
import AppMarketplaceLink from '../AppMarketplaceLink'

const AppIntroduction = () => {
  return (
    <div className='my-5 d-flex flex-column gap-0 gap-md-5'>
      <HeroImage
        title="Stop Flipping the Hard Way!"
        text={(
          <>
            Flippers have unmatched hustle.
            But scaling a flipping business takes more than hustle - you need efficient process and technology.
            <br/>
            <br/>
            That's where the <a href="https://www.ivyflip.com/product">Ivy Flip mobile app</a> comes in.
            <br/>
            <br/>
            Our convenient workflow centralizes all of your sourcing, listing, and sales tracking in one location,
            helping you reclaim hours every day and make more money!
          </>
        )}
        image={(
          <div className={[styles.container, 'd-flex gap-4'].join(' ')}>
            <div
              className={[styles.marketplaces, 'd-flex gap-4'].join(' ')}
            >
              <AppMarketplaceLink marketplace='apple' />
              <AppMarketplaceLink marketplace='google' />
            </div>

            <div className='w-100'>
              <img
                src='/img/landing/index/app-new.png'
                alt={`Ivy Flip Application for iOS and Android | Ivy Flip`}
                className={[styles.image, 'd-none d-md-block br-3'].join(' ')}
              />
              <img
                src='/img/landing/index/app-small.png'
                alt={`Ivy Flip Application for iOS and Android | Ivy Flip`}
                className={[styles.image, 'd-md-none br-3'].join(' ')}
              />
            </div>
          </div>
        )}
        background={'bg-white'}
        h1={false}
      />

      <div className='px-0 px-md-4 px-lg-5 d-flex'>
        <Row>
          <Col xs={12} md={6}>
            <div
              className="br-3 p-4 p-md-5 h-100"
              style={{ border: '10px solid var(--bs-light)' }}
            >
              <div style={{ margin: -10 }}>
                <div className="fs-3 fw-bold mb-4">
                  Manual Sourcing <span className='ms-2'>🐌</span>
                </div>
                <FeaturesList
                  features={[
                    [ -1, 'Spend hours searching online marketplaces.' ],
                    [ -1, 'Sift through a lot of irrelevant listings.' ],
                    [ -1, 'Miss out on good deals because they get snatched up by your competition.' ],
                    [ -1, 'End up with a lot of duds that don\'t sell.' ]
                  ]}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="bg-light br-3 p-4 p-md-5 h-100 mt-4 mt-md-0">
              <div className="fs-3 fw-bold mb-4">
                Sourcing with Ivy&nbsp;Flip&nbsp;<span className='ms-2'>🚀</span>
              </div>
              <FeaturesList
                features={[
                  [ 1, 'Get notified whenever new couches are available near you.' ],
                  [ 1, 'Sort through available couches in the feed.' ],
                  [ 1, 'Swipe right for couches, swipe left to pass.' ],
                  [ 1, 'Find the best couch deals first, make more money.' ]
                ]}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="d-flex flex-row justify-content-center mt-5 mt-md-0">
        <Col xs={12} md={6} lg={4}>
          <PrimaryCtaButton />
        </Col>
      </div>
    </div>
  )
}

export default AppIntroduction