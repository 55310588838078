const FeaturesList = ({features}) => {
  return (
    <div className='d-flex flex-column gap-2'>
      {features.map((t, index) => (
        <div
          key={index}
          className='d-flex align-items-baseline w-100'
        >
          <div
            style={{
              width: '1rem',
              height: '1rem',
              fontSize: '1rem'
            }}
            className='text-center me-2 flex-shrink-0'
          >
            {t[0] === 2 && <span>🔥</span>}
            {t[0] === 1 && <span className='text-success fw-bold'>✓</span>}
            {t[0] === 0 && <span className='text-dark fw-bold'>✓</span>}
            {t[0] === -1 && <span className='text-danger'>✘</span>}
            {t[0] === -2 && <span className='text-dark'>✘</span>}
          </div>
          <div
            className={
              t[0] === -2
                ? 'text-dark'
                : ''
            }
            style={{
              textDecoration: t[0] === -2 ? 'line-through' : undefined,
              fontWeight: t[2] ? 600 : undefined,
              fontSize: '.9rem'
            }}
          >
            {t[1]}
          </div>
        </div>
      ))}
    </div>
  )
}

export default FeaturesList
