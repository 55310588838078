import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const HeroImage = ({
  title,
  text,
  image,
  buttons,
  background = 'bg-light',
  color = 'text-black',
  h1 = true
}) => {
  return (
    <Row className='mb-3'>
      <Col xs={12}>
        <div className={`${background} ${color} br-3 ${ background === 'bg-white' ? '' : 'p-1 p-md-4 p-lg-5'}`}>
          <Row>
            <Col xs={12} md={6}>
              <div className='h-100'>
                {image}
              </div>
            </Col>
            <Col
              xs={12} md={6}
              className='mt-3 mt-md-0'
            >
              <div className='h-100 d-flex flex-column justify-content-center gap-4 px-4'>
                {h1 && (
                  <h1 className="fw-bold mb-0 mt-2">
                    {title}
                  </h1>
                )}
                {!h1 && (
                  <div className="display-5 fw-bold">
                    {title}
                  </div>
                )}
                <div className="lead">
                  {text}
                </div>
                {buttons && (
                  <div className="d-flex flex-column flex-lg-row align-items-stretch w-100">
                    {buttons}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default HeroImage
