const Accent = ({ children, type }) => {
  return (
    <span
      className={
        `d-inline-block br-2 fw-normal fs-5 px-2 py-1 bg-opacity-50 ` +
        `${type === 'success' ? 'bg-success' : ''}` +
        `${type === 'danger' ? 'bg-danger' : ''}` +
        `${type === 'warning' ? 'bg-warning' : ''}`
      }
    >
      {children}
    </span>
  )
}

export default Accent