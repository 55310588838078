import { Button } from 'react-bootstrap'
import Link from 'next/link'

const PrimaryCtaButton = () => {
  return (
    <>
      <div className={`mb-1 d-flex gap-1 flex-row w-100`}>
        <Link href={`/app/subscription?new=growth`}>
          <Button
            variant='success'
            size='lg'
            className={`px-5 py-3 w-100`}

          >
            Start Free Trial
          </Button>
        </Link>
      </div>
      <div className="text-muted text-center fs-6">
         7 Days Free Trial • Cancel Anytime
      </div>
    </>
  )
}

export default PrimaryCtaButton