import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const Hero = ({
  title,
  text,
  buttons,
  background = 'bg-white',
  color = 'text-black'
}) => {
  return (
    <div className={`my-5 ${background} ${color} br-3`}>
      <Row>
        <Col xs={12}>
          <div className={background === 'bg-white' ? 'px-3 px-md-4 px-lg-5' : 'p-3 p-md-4 p-lg-5'}>
            <div className="px-0 px-lg-1 py-2 py-lg-5 d-flex flex-column align-items-center text-center">
              <h1 className="display-3 fw-bold">
                {title}
              </h1>
              <div className={`lead ${buttons ? 'mb-5' : ''}`}>
                {text}
              </div>
              {buttons && (
                <div className="d-flex flex-column flex-md-row align-items-stretch justify-content-center w-100">
                  {buttons}
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Hero
