import Image from 'next/image'

const FeatureImage = ({
  src,
  title,
}) => {
  return (
    <div style={{
      width: '100%',
      maxWidth: 400,
      aspectRatio: 1,
      position: 'relative'
    }}>
      <Image
        src={src}
        alt={`${title} | Ivy Flip`}
        loading='lazy'
        layout='fill'
        objectFit='contain'
      />
    </div>
  )
}

export default FeatureImage
