import qs from 'qs'

/**
 * Get full Strapi URL from path
 * @param {string} path Path of the URL
 * @returns {string} Full Strapi URL
 */
export function getStrapiURL (path = '') {
  return `${
    typeof window === 'undefined'
      ? process.env.BLOG_API_URL
      : (process.env.BLOG_API_URL_PUBLIC ?? process.env.BLOG_API_URL)
  }${path}`
}

/**
 * Helper to make GET requests to Strapi API endpoints
 * @param {string} path Path of the API route
 * @param {Object} urlParamsObject URL params object, will be stringified
 * @param {Object} options Options passed to fetch
 * @returns Parsed API call response
 */
export async function fetchAPI (path, urlParamsObject = {}, options = {}) {
  // Merge default and user options
  const mergedOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${process.env.BLOG_API_TOKEN}`
    },
    ...options
  }

  // Build request URL
  const queryString = qs.stringify(urlParamsObject)
  const requestUrl = `${getStrapiURL(
    `/api${path}${queryString ? `?${queryString}` : ''}`
  )}`

  let response
  try {
    response = await fetch(requestUrl, mergedOptions)
  } catch (error) {
    console.error(error)
    return null
  }

  if (!response.ok) {
    console.error(response)
    return null
  }

  let data
  try {
    data = await response.json()
  } catch (error) {
    console.error(error)
    return null
  }

  return data
}

export async function fetchSEO (pageSlug) {
  try {
    const seoRes = await fetchAPI('/seos', {
      filters: { pageSlug },
      populate: ['shareImage']
    })

    if (!seoRes) {
      return null
    }

    const seo = seoRes.data[0]

    if (!seo) {
      return null
    }

    return {
      metaTitle: seo.attributes.title,
      metaDescription: seo.attributes.description,
      shareImage: seo.attributes.shareImage,
      schema: seo.attributes.schema
    }
  } catch (e) {
    console.error(e)
    return null
  }
}
