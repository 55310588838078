import Image from 'next/image'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const testimonials = [
  {
    image: '/img/landing/persons/person-alex.jpg',
    name: 'Alex Crumlish',
    location: 'Middleboro, Massachusetts',
    text: '“My storage is so full I can’t even pick up more couches.' +
      ' I picked up 3 sofas that will go for $1800-1900 wholesale value between today and yesterday for $350.”'
  },
  {
    image: '/img/landing/persons/person-daniel.jpg',
    name: 'Daniel Laskowski',
    location: 'Orlando, Florida',
    text: '“Just wanted to say I\'m really enjoying Ivy Flip. ' +
      'I don\'t check it all week and then when I need to restock ' +
      'I can spend 2-3 hours and get 6-8 couch pickups for the next day. ' +
      'Got my entire weeks worth of couches scheduled in one day just because ' +
      'I had quick access to pretty much every couch that\'s been posted.”'
  },
  {
    image: '/img/landing/persons/person-tim.jpg',
    name: 'Tim Payne',
    location: 'Chicago, IL',
    text: '“Ivy Flip has been awesome. Thanks again!”'
  },
  {
    image: '/img/landing/persons/person-farley.jpg',
    name: 'Farley Granger',
    location: 'Cerritos, CA',
    text: '“I\'ve changed my inventory and hit a new PR for the last week or so, ' +
      'so definitely staying on board and going for higher numbers.”'
  }
]

const TextTestimonials = () => {
  const imageSize = 80
  const imagePadding = 4

  return (
    <>
      <Row className='my-5'>
        {testimonials.map((item, index) => (
          <Col
            key={index}
            xs={12} lg={6} xl={3}
          >
            <div className='py-1 py-xl-0'>
              <div className="p-4 bg-light br-3 h-100 d-flex flex-column">
                <div>
                  <span className='fs-1 fw-bold text-warning d-inline' style={{ lineHeight: 1 }}>“</span>
                </div>
                <div>
                  {item.text}
                </div>
                <div className='d-flex gap-3 mt-4'>
                  <div
                    className='bg-success flex-shrink-0'
                    style={{
                      borderRadius: '100%',
                      width: imageSize,
                      height: imageSize,
                      padding: imagePadding
                    }}
                  >
                    <Image
                      src={item.image}
                      alt={`${item.name} | Iyvflip Review`}
                      loading="lazy"
                      width={ imageSize - imagePadding * 2}
                      height={ imageSize - imagePadding * 2}
                      style={{ borderRadius: '100%' }}
                    />
                  </div>
                  <div
                    className='d-flex flex-column justify-content-center'
                    style={{ lineHeight: 1.2 }}
                  >
                    <div className="fs-5 fw-semibold mb-1">{item.name}</div>
                    <div>{item.location}</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default TextTestimonials
