import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import styles from '../../styles/components/VideoTestimonials.module.css'
import PrimaryCtaButton from './PrimaryCtaButton'
import { MuxVideo } from '../MuxVideo'

const videos = [
  {
    width: 202.5,
    playbackId: 'leorX53avkAdX02jkRS1YrhUSqnYasmEVVHFtS00cVXIs'
  },
  {
    width: 202.5,
    playbackId: 'e7w01pBkuVRClrGO26o00ANy57VhDizb5JuIefCIcSHM4'
  },
  {
    width: 202.5,
    playbackId: '9GmIsM02lqU00Pg4bXbaDruPgFPGXzdC00PHbOtMofSJp00'
  },
  {
    width: 480,
    playbackId: 'uwNnF9z02civnOSDwszYxywqu3mIN9E01l5MzuIpc7A00g'
  },
  {
    width: 640,
    playbackId: '4Nn4TAgar8Z02UgzUQib1QDZYZdi0200qBzuMAn01bLzUoY'
  },
  {
    width: 640,
    playbackId: 'mPmNTQtgoHVwKwx024qXTJsJMducDD016eyjUuNUlUTs00'
  },
  {
    width: 202.5,
    playbackId: 'iQBNccjpPFH9xzUWJG02DFBA84jcnWz1o0201d8HoiOfh00'
  },
  {
    width: 640,
    playbackId: 'ReSDfjguOiLbCGIIKrR3jOd6xO02ZETsBDTN9RKOCWjs'
  }
]

export const VideoTestimonials = () => {
  const [playingVideoId, setPlayingVideoId] = useState(null)

  const playVideo = (id) => {
    if (playingVideoId === id) {
      setPlayingVideoId(null)
    } else {
      setPlayingVideoId(id)
    }
  }

  return (
    <div
      id='testimonials'
      className='my-5'
      style={{
        height: '400px',
        position: 'relative'
      }}
    >
      <div
        className='bg-primary py-3'
        style={{
          position: 'absolute',
          transform: 'translate(-50%, 0)', left: '50%', width: '100vw',
          top: 0, bottom: 0,
          overflowX: 'auto',
          overflowY: 'hidden'
        }}
      >
        <Container className='d-flex'>
          <div className='me-4 p-md-4 flex-shrink-0 d-flex flex-column justify-content-center text-center text-white br-3'>
            <div className='display-6 fw-semibold'>
              Hear from
              <br/>our users!
            </div>
            <div className='fs-1 mb-2'>
              👉
            </div>
            <div className={styles.primaryButtonContainer}>
              <PrimaryCtaButton />
            </div>
          </div>

          {videos.map((video) => (
            <MuxVideo
              key={video.playbackId}
              id={video.playbackId}
              width={video.width}
              playVideo={playVideo}
              isPlaying={playingVideoId === video.playbackId}
              className='me-4'
            />
          ))}
        </Container>
      </div>
    </div>
  )
}
